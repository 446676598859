import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { TableOfContents, Image, PreviewContent, Content, Layout, Metadata } from '../components';

export function CustomPageTemplate({ title, image, content, tableOfContents, isPreviewMode }) {
  const PageContent = isPreviewMode ? PreviewContent : Content;

  return (
    <>
      <section>
        <div className="full-width-image fixed-background slim" id="hero-image">
          <Image src={image} />
          <div className="container">
            <h1>{title}</h1>
          </div>
        </div>

        <section>
          <div className="container">
            <div>
              <PageContent>{content}</PageContent>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

CustomPageTemplate.propTypes = {
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  title: PropTypes.string.isRequired,
  tableOfContents: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  content: PropTypes.string,
  isPreviewMode: PropTypes.bool,
};

CustomPageTemplate.defaultProps = {
  image: '',
  tableOfContents: [],
  content: '',
  isPreviewMode: false,
};

const CustomPage = ({ data }) => {
  const { frontmatter, body, tableOfContents } = data.mdx;

  const { metadata } = frontmatter;
  const title = metadata?.title.length > 1 ? metadata.title : frontmatter.title;
  const description = metadata?.description.length > 1 ? metadata.description : '';

  return (
    <Layout>
      <Metadata title={title} description={description} image={frontmatter.image} />
      <CustomPageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        tableOfContents={frontmatter.showTableOfContents ? tableOfContents.items : []}
        content={body}
      />
    </Layout>
  );
};

CustomPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
      body: PropTypes.string,
      tableOfContents: PropTypes.object,
      showTableOfContents: PropTypes.bool,
    }),
  }).isRequired,
};

export default CustomPage;

export const customPageQuery = graphql`
  query CustomPageTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      frontmatter {
        title
        showTableOfContents
        metadata {
          title
          description
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 1024,
              quality: 100,
              duotone: {
                highlight: "#cc3232",
                shadow: "#51133d"
              }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
